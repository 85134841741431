import React from "react";
import { map, isEmpty } from "ramda";
import Img from "gatsby-image";

const Realisation = ({id, date, title, logo, job, items = [], tags = [], url = "", clientName }) => (
  <div id="realisations" className="box">
    <div className="box-content">
      <div className="box-header">
        <div className="columns is-2 is-multiline">
          <div className="column is-block is-narrow has-text-centered-mobile">
            <Img fixed={logo} />
          </div>
          <div className="column is-narrow is-flex is-flex-direction-column has-text-centered-mobile">
              <h3>{title}</h3>
              {clientName && <h4>{clientName}</h4> }
              <p className="job">{job}</p>
              <p className="title">{date}</p>
          </div>
        </div>
      </div>

      {!isEmpty(items) &&
        map((item) => (
          <p key={`realisation-${id}-${item}`}>
            <span className="icon checkmark"></span>
            <span className="realisation-item">{item}</span>
          </p>
        ))(items)}
      {
        url && <a className="my-1" target="_blank" href={url}>{url}</a>
      }
      {!isEmpty(tags) && (
        <div className="mt-4 tags">
          {map((tag) => (
            <span key={`realisation-${id}-${tag}`} className="tag is-primary is-large-desktop">{tag}</span>
          ))(tags)}
        </div>
      )}
   
    </div>
  </div>
);

export default Realisation;
