import React from "react";
import Advertising from "./advertising";
import Competences from "./competences";
import QuiSuisJe from "./qui-suis-je";
import Realisations from "./portefeuille-realisations";
import Contact from "../contact";

const Home = () => (
  <>
    <Advertising />
    <Competences />
    <Realisations />
    <QuiSuisJe />
    <Contact />
  </>
);

export default Home;
