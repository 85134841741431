import { equals } from "ramda";
import React from "react";
import Home from "../components/home";
import Layout from "../components/layout";
import NotificationProvider, { NotificationContext } from "../store/NotificationProvider";

const IndexPage = () => {
  const INITIAL_STATE = {
    opened: false,
    message: "",
    type: "",
  };

  return (
    <NotificationProvider>
      <NotificationContext.Consumer>
        { ({notification, setNotification}) =>
          <div
            className={`modal ${notification &&
              notification.opened &&
              "is-active"}`}
          >
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <button
                  className="delete has-text-right"
                  onClick={() => setNotification({ ...INITIAL_STATE })}
                  aria-label="close"
                ></button>
              </header>
              <section className="modal-card-body">
                <div className="modal-icon">
                  <span
                    className={`icon is-left ${
                      equals("error")(notification.type) ? "red" : "green"
                    }`}
                  >
                    <i
                      className={`fas is-small ${
                        equals("error")(notification.type)
                          ? "fa-times-circle"
                          : "fa-check-circle"
                      }`}
                    />
                  </span>
                </div>
                <div className="has-text-centered">{notification.message}</div>
              </section>
              <footer className="modal-card-foot"></footer>
            </div>
          </div>
        }
      </NotificationContext.Consumer>
      <Layout>
        <Home />
      </Layout>
    </NotificationProvider>
  );
};

export default IndexPage;
