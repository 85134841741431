import React, { useEffect } from "react";
import "./contact.style.scss";
import loadJsToken from "../../scripts/loadJsToken";
import { NotificationContext } from "../../store/NotificationProvider";

const onSubmit = (e) => {
  e.preventDefault();
  window.jsTokenAccept("nom", "sujet", "email", "message");
};

const clean = () => {
  document.querySelector("#nom").value = "";
  document.querySelector("#sujet").value = "";
  document.querySelector("#email").value = "";
  document.querySelector("#message").value = "";
};

const wasmBrowserInstantiate = async (wasmModuleUrl, importObject) => {
  let response;

  // Check if the browser supports streaming instantiation
  if (WebAssembly.instantiateStreaming) {
    // Fetch the module, and instantiate it as it is downloading
    response = await WebAssembly.instantiateStreaming(
      fetch(wasmModuleUrl),
      importObject
    );
  } else {
    // Fallback to using fetch to download the entire module
    // And then instantiate the module
    const fetchAndInstantiateTask = async () => {
      const wasmArrayBuffer = await fetch(wasmModuleUrl).then((response) =>
        response.arrayBuffer()
      );
      return WebAssembly.instantiate(wasmArrayBuffer, importObject);
    };

    response = await fetchAndInstantiateTask();
  }
  return response;
};

const runWasmAdd = async () => {
  const go = new window.Go();
  // Get the importObject from the go instance.
  const importObject = go.importObject;

  // Instantiate our wasm module
  const wasmModule = await wasmBrowserInstantiate("jstoken.wasm", importObject);

  // Allow the wasm_exec go instance, bootstrap and execute our wasm module
  go.run(wasmModule.instance);
  window.jsTokenEndPoint("https://golivery.zzx.fr");
  window.jsTokenLicence("9e5a934b-d8ef-4065-a679-85ac251c8f0a");
};

const Contact = ({ setNotification }) => {
  useEffect(() => {
    loadJsToken(() => {
      const JSTokenOK = () => {
        setNotification({
          opened: true,
          message: "l'email à été envoyé avec succès",
          type: "success",
        });
        clean();
      };
      const JSTokenError = () =>
        setNotification({
          opened: true,
          message: "l'email à été envoyé avec succès",
          type: "error",
        });

      document.addEventListener("JSTokenOK", JSTokenOK);
      document.addEventListener("JSTokenError", JSTokenError);
      runWasmAdd();
      return () => {
        document.removeEventListener("JSTokenOK", JSTokenOK);
        document.removeEventListener("JSTokenError", JSTokenError);
      };
    });
  }, []);

  return (
    <section className="section" id="contact" data-component="Contact">
      <div className="container">
        <div className="card">
          <div className="columns is-centered">
            <div className="column is-10 has-text-centered">
              <p className="surtitre">Une question ? Un projet web ?</p>
              <h2>Travaillons ensemble</h2>
              <p>
                Vous avez un projet, un site web à réaliser, vous cherchez un
                développeur web ou vous souhaitez simplement échanger&nbsp;?
              </p>
              <p className="has-text-weight-bold">Contactez-moi&nbsp;!</p>

              <section className="section contact-body">
                <div className="container">
                  <form onSubmit={onSubmit}>
                    <div className="columns is-centered">
                      <div className="column is-10">
                        <div className="columns is-variable is-8 is-multiline">
                          <div className="field column is-6-desktop is-12-touch">
                            <div className="control">
                              <input
                                id="nom"
                                name="nom"
                                className="input is-medium border-underlined"
                                type="text"
                                maxLength="30"
                                placeholder="Nom"
                                required
                              />
                            </div>
                          </div>
                          <div className="field column is-6-desktop is-12-touch">
                            <div className="control">
                              <input
                                id="email"
                                name="email"
                                className="input is-medium border-underlined"
                                type="email"
                                maxLength="30"
                                placeholder="Email"
                                required
                              />
                            </div>
                          </div>
                          <div className="field column is-12">
                            <div className="control">
                              <input
                                id="sujet"
                                name="sujet"
                                className="input is-medium border-underlined"
                                type="text"
                                maxLength="30"
                                placeholder="Sujet"
                                required
                              />
                            </div>
                          </div>
                          <div className="field column is-12">
                            <div className="control">
                              <textarea
                                id="message"
                                name="message"
                                className="textarea is-medium border-underlined"
                                maxLength="300"
                                placeholder="Message"
                                required
                              ></textarea>
                            </div>
                          </div>
                          <div className="field column is-12">
                            <div className="control has-text-left has-text-centered-mobile">
                              <button
                                type="submit"
                                className="button button-gradient-purple"
                              >
                                Envoyer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default () => (
  <NotificationContext.Consumer>
    {(store) => <Contact {...store} />}
  </NotificationContext.Consumer>
);
