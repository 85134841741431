import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { map } from "ramda";
import "./portefeuille-realisations.style.scss";
import { mapItemListFromJsonWithImage } from "../../../common.utils";
import Realisation from "./realisation";

const Realisations = ({ realisationList = [] }) => (
  <section data-component="realisations" className="section">
    <div className="container">
      <div className="columns is-multiline is-centered">
        <div className="column realisations-header mb-3 is-8 has-text-centered">
          <p className="surtitre">Développement Front-End</p>
          <h2 className="mb-4">Mes réalisations</h2>
          <p>
            Retrouvez les derniers projets sur lesquels j’ai oeuvré en tant que
            Développeur web Front-End.
          </p>
        </div>

        <div>
          <div className="column is-12">
            <div className="columns realisations is-variable is-16 is-multiline is-tablet">
              {map((realisation) => (
                <div key={realisation.id} className="column is-really-padded is-6">
                  <Realisation {...realisation} />
                </div>
              ))(realisationList)}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        realisationList: allRealisationsJson {
          edges {
            node {
              id
              title
              url
              date
              items
              tags
              logo
              job
              clientName
            }
          }
        }

        biig: file(relativePath: { eq: "realisations/biig.png" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        anode: file(relativePath: { eq: "realisations/anode.png" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        mosica: file(relativePath: { eq: "realisations/mosica.png" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        flbp: file(relativePath: { eq: "realisations/flbp.png" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        maif: file(relativePath: { eq: "realisations/maif.png" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        smacl: file(relativePath: { eq: "realisations/smacl.jpeg" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        soprasteria: file(
          relativePath: { eq: "realisations/soprasteria.jpeg" }
        ) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        tipsly: file(relativePath: { eq: "realisations/logo-tipsly.png" }) {
          childImageSharp {
            fixed(width: 100, height: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }

        incoming: file(relativePath: { eq: "realisations/soprasteria.jpeg" }) {
          childImageSharp {
            fixed(width: 50, height: 50, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={({ realisationList, ...rest }) => {
      return (
        <Realisations
          realisationList={mapItemListFromJsonWithImage(rest)(realisationList)}
          {...props}
        />
      );

    }}
  />
);
