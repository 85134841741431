import React from 'react'
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import './qui-suis-je.style.scss'

const QuiSuisJe = ({ quisuisje }) =>
  <section className="section" id="quisuisje" data-component="Quisuisje">
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-6-desktop is-12-touch">
          <p className="surtitre">Qui suis-je ?</p>
          <h2>Un développeur inventif & précis</h2>
          <p className="mt-5-5">
            Fort d’un diplôme d’ingénieur en informatique, cela fait près de 10 ans que je développe des applications logicielles et web pour des grands groupes tels que la Macif ou la Maif.
        </p>
          <p className="mt-5-5">
            C’est après plusieurs années en tant que développeur Java et développeur Lead Front JS dans différentes agences web et ESN/SSII, que j’ai décidé de créer ma propre entreprise début 2020 à Angoulême.
        </p>
          <p className="mt-5-5">
            Véritable passionné par le développement web et applicatif, je possède de solides compétences sur les technologies Javascript / ReactJS / Gatsby et Java.
        </p>
        </div>
        <div className="column is-6-desktop is-12-touch">
          <Img fluid={quisuisje} />
        </div>

      </div>
    </div>

  </section>



export default props =>
  <StaticQuery
    query={graphql`
    query {

      quisuisje: file(relativePath: { eq: "backgrounds/dessin-developpeur.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1080) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }          
    }
    `}

    render={({ quisuisje }) =>
      <QuiSuisJe quisuisje={quisuisje.childImageSharp.fluid} {...props} />
    }
  />


