import React from 'react'
import './advertising.style.scss'

const Advertising = () =>
  <div data-component="advertising">
    <section className="section">
      <div className="container">
        <div className="card">
          <div className="columns is-centered">
            <div className="column is-10 has-text-centered">
              <p className="surtitre">Un expert Javascript à votre écoute</p>
              <h2>
                Vous recherchez un développeur Front ?
              </h2>
              <p className="mt-6">
                Développeur web freelance en remote et sur Angoulême, je vous accompagne tout au long de vos projets web.
                De l'intégration au développement en passant par la connexion back-end, je maitrise tous les enjeux front-end
              </p>
                <a className="button button-gradient-purple mt-6" href="https://www.linkedin.com/in/romain-barraud/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>

export default Advertising




