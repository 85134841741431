import { pipe, prop, map } from 'ramda'

export const mapItemListFromJson = pipe(
  prop('edges'),
  map(prop('node'))
)

export const mapItemListFromJsonWithImage = (data) =>
  pipe(
    prop('edges'),
    map(prop('node')),
    map((el) => ({
      ...el,
      logo:
        el.logo && data[el.logo]
          ? data[el.logo].childImageSharp.fixed
          : data["Incoming"].childImageSharp.fixed,
    }))
  );