import React, { createContext, Component } from "react"; // on importe createContext qui servira à la création d'un ou plusieurs contextes

export const NotificationContext = createContext({
  opened: false,
  message: "",
  type: "",
});

class NotificationProvider extends Component {
  state = {
    notification: {
      opened: false,
      message: "",
      type: "",
    },
    setNotification: (notification) =>
      this.setState({ notification: { ...notification } }),
  };

  render() {
    return (
      <NotificationContext.Provider value={this.state}>
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}

export default NotificationProvider;
