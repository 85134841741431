import React from "react";
import { map, isEmpty } from "ramda";
import { graphql, StaticQuery, Link } from "gatsby";
import { mapItemListFromJson } from "../../../common.utils";
import "./competences.style.scss";

const createCompetence = ({ svg, category, skills = [] }) => (
  <div
    key={category}
    className="skill mb-4 column is-half has-text-centered-mobile"
  >
    <div>
      <span className={`icon ${svg}`}> </span>
      <h3>{category}</h3>
      {!isEmpty(skills) && (
        <ul className="mt-4">
          {map((skill) => <li key={`${category}-${skill}`}>{skill}</li>)(
            skills
          )}
        </ul>
      )}
    </div>
  </div>
);

const Competences = ({ skillsList = [] }) => (
  <div data-component="skills" id="skills">
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6">
            <p className="surtitre">Mes compétences</p>
            <h2>Expert Javascript / ReactJS & Java</h2>
            <p className="big-text mt-6">
              Avant-tout développeur Front-end, mes compétences s’articulent
              autour de Javascript pour le développement web notamment avec le
              framework ReactJS mais je suis également spécialisé dans le
              langage Java avec lequel j’ai réalisé de nombreuses applications
              pour différentes mutuelles de renom.
            </p>
            <div className="has-text-centered-mobile">
                <Link className="button mt-6 button-gradient-purple" to="/#contact">Me contacter</Link>
            </div>
          </div>
          <div className="skillslist column is-offset-1 is-5">
            <div className="columns is-multiline">
              {map((skill) => createCompetence(skill))(skillsList)}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        skillsList: allSkillsJson {
          edges {
            node {
              category
              skills
              svg
            }
          }
        }
      }
    `}
    render={({ skillsList }) => (
      <Competences {...props} skillsList={mapItemListFromJson(skillsList)} />
    )}
  />
);
